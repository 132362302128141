<template>
  <v-container class="pa-0" fluid>
    <kurcc-about-header/>
    <v-row no-gutters>
      <v-col cols="12" lg="8" md="10" offset-lg="2" offset-md="1">
        <kurcc-about-body/>
        <kurcc-back-to-top class="my-10"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KurccAboutPage',
  components: {
    KurccAboutHeader: () => import('@/modules/about/components/KurccAboutHeader'),
    KurccAboutBody: () => import('@/modules/about/components/KurccAboutBody'),
    KurccBackToTop: () => import('@/modules/app/components/KurccBackToTop')
  }
}
</script>
